@import "../../../themes/_colors";

.nos-brandis__checkbox {
    &__container {
        &-main {
            display: flex;
            align-items: center;
            padding-bottom: 0.5rem;
        }

        &-label {
            display: flex;
            align-items: center;
            margin-top: 0.85rem;
        }
    }

    &__icon {
        width: 1.25rem;
        height: 1.25rem;
        cursor: pointer;
    }

    &__text {
        margin: 0.25rem 0 0 0.25rem;
    }
}
