@import "../../../themes/_colors";

.nos-brandis__filter-pills {
    &__container {
        &-main {
            display: flex;
            margin-left: 1rem;
            border: 1px solid $border-color;
            align-items: center;
            justify-content: center;
            border-radius: 3rem;
            background-color: $pill-background;
            box-shadow: 0 8px 6px -6px $shadow-color-2;
        }

        &-normal {
            padding: 0.55rem 1rem 0.45rem 1rem;
        }

        &-badged {
            padding: 0.75rem 1rem 0.6rem 1rem;
        }

        &-badge {
            margin-left: 0.75rem;
            background: $badge-container-background;
            /* color: black; */
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 1rem;
            padding: 0.1rem 0.7rem 0;
        }
    }

    &__text {
        font-size: 1rem;
        font-weight: 300;
        color: $pill-font;
    }

    &__badge {
        font-size: 1rem;
        font-weight: 700;
        color: $badge-font;
    }
}
