@import "../../../themes/_colors";

.nos-brandis__button {
    // naming it btn otherwise it becomes button__button
    // looking for better name
    &__btn-primary {
        border: none;
        border-radius: 0.3rem;
        padding: 0.75rem 1.2rem;
        font-size: 1.15rem;
        cursor: pointer;
        color: $white;
        background-color: $button-primary-background;
        box-shadow: 0 0 4px $button-box-shadow;
        outline: none;
        background-position: center;
        transition: background 0.8s;

        &:hover {
            background: $button-primary-hover
                radial-gradient(circle, transparent 1%, $button-primary-hover 1%) center/15000%;
        }
        &:active {
            background-color: $button-primary-active;
            background-size: 100%;
            transition: background 0s;
        }
    }

    &__btn-secondary {
        border: none;
        border-radius: 0.3rem;
        padding: 0.75rem 1.2rem;
        font-size: 1.15rem;
        cursor: pointer;
        color: $white;
        background-color: $button-secondary-background;
        box-shadow: 0 0 4px $button-box-shadow;
        outline: none;
        background-position: center;
        transition: background 0.8s;

        &:hover {
            background: $button-secondary-hover
                radial-gradient(circle, transparent 1%, $button-secondary-hover 1%) center/15000%;
        }
        &:active {
            background-color: $button-secondary-active;
            background-size: 100%;
            transition: background 0s;
        }
    }
}
