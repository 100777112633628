@import "../../../themes/_colors";

.nos-brandis__filter-strip {
    &__container {
        &-main {
            display: flex;
            padding: 1rem 2rem;
            justify-content: flex-start;
            align-items: center;
        }
        &-pill {
            display: flex;
            flex: 1;
            justify-content: flex-start;
            align-items: center;
            height: 3rem;
        }
    }
    &__toggle-button {
        cursor: pointer;
        width: 1.75rem;
        height: 1.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.3rem;
        background: $filter-button-color;
        outline: none;
        border: none;
        box-shadow: 0px 7px 8px -7px $shadow-color-2;
    }

    &__filter-label {
        font-size: 1.25rem;
        padding: 0.4rem 0.5rem 0 1.5rem;
        color: grey;
        font-weight: 400;
    }
}
