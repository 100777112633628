@import "../../../themes/_colors";

.nos-brandis__date-filter {
    &__container {
        &-main {
            display: flex;
            flex-direction: column;
            padding: 1rem 0rem;
        }

        &-picker {
            padding: 0.5rem 0;
            display: flex;
            flex-direction: column;
        }
    }

    &__label {
        &-category {
            font-size: 1.25rem;
            font-weight: 600;
        }

        &-range {
            font-size: 1.15rem;
            font-weight: 600;
        }
    }

    &__picker {
        border: 1px solid $input-outline;
        font-size: 1.15rem;
        border-radius: 0.2rem;
        outline: none;
        cursor: pointer;
        height: 2rem;
    }
}
