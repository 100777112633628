@import "../../../themes/_colors";

.nos-brandis__summary {
    &__container {
        &-main {
            display: flex;
            padding: 2rem 2rem 0 2rem;
            justify-content: space-between;
        }
    }
}
