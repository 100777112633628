@import "../../../themes/_colors";

.nos-brandis__selectable-button {
    &__normal {
        border: none;
        border-radius: 0.3rem;
        padding: 0.5rem 1rem;
        font-size: 1.15rem;
        cursor: pointer;
        color: $black;
        background-color: $button-selectable-background;
        box-shadow: 0 0 4px $button-box-shadow;
        outline: none;
        background-position: center;
        transition: background 0.8s;
        width: 9rem;
        height: 3rem;

        &:hover {
            background: $button-selectable-hover
                radial-gradient(circle, transparent 1%, $button-selectable-hover 1%) center/15000%;
        }
        &:active {
            background-color: $button-selectable-active;
            background-size: 100%;
            transition: background 0s;
        }
    }

    &__selected {
        border: none;
        border-radius: 0.3rem;
        padding: 0.5rem 1rem;
        font-size: 1.15rem;
        cursor: pointer;
        color: $white;
        background-color: $button-primary-background;
        box-shadow: 0 0 4px $button-box-shadow;
        outline: none;
        background-position: center;
        transition: background 0.8s;
        width: 9rem;
        height: 3rem;

        &:hover {
            background: $button-primary-hover
                radial-gradient(circle, transparent 1%, $button-primary-hover 1%) center/15000%;
        }
        &:active {
            background-color: $button-primary-active;
            background-size: 100%;
            transition: background 0s;
        }
    }
}
