@import "../../../themes/_colors";

.nos-brandis__filter-selector {
    &__container {
        &-main {
            display: flex;
            background-color: $primary-background;
            flex-direction: column;
        }

        &-date-range {
            display: flex;
            flex-direction: column;
            padding: 0rem 0rem 5rem 0rem;
        }

        &-selector {
            &-strip {
                display: flex;
                padding: 1rem 1rem 1rem 2rem;
                overflow-x: auto;
                flex: 5;
                border-bottom: 1px solid $divider-color;
            }

            &-view {
                // display: flex;
                background: $view-menu-background;
                flex: 1.2;
                padding: 2rem 0rem 2rem 2rem;
                max-height: 26.5rem;
                min-width: 23rem;
                box-shadow: 0 0px 2px rgba(0, 0, 0, 0.3), 0 0 10px rgba(0, 0, 0, 0.1) inset;
                overflow-y: auto;
                // flex-wrap: wrap;
            }

            &-menu {
                display: flex;
                flex-wrap: wrap;
            }
        }

        &-top {
            display: flex;
        }

        &-bottom {
            display: flex;
            padding: 0.5rem 2rem;
            justify-content: center;
            align-items: center;
        }

        &-button {
            display: flex;
            padding: 1rem;
        }
    }

    &__menu-item-wrapper {
        display: flex;
        margin: 0.5rem 1rem;
    }

    &__view-label {
        font-size: 1.25rem;
        font-weight: 600;
        padding-left: 1rem;
    }
}
