@import "../../../../themes/_colors";

.nos-brandis__chart-wrapper {
    &__container {
        &-main {
            display: flex;
            flex: 0 0 47.5%;
            margin-top: 1.5rem;
            border: 1px;
            background-color: $charts-container-background;
            border-radius: 2px;
            box-shadow: 0 0 4px $charts-container-box-shadow;
            padding: 1rem;
            flex-direction: column;
        }

        &-chart {
            display: flex;
            justify-content: FLEX-START;
            align-items: center;
            padding-top: 3rem;
        }
        &-error-message {
            display: flex;
            flex: 1;
            justify-content: center;
            font-size: 1rem;
            color: $grey-shade-5;
            padding: 0px 18rem;
            text-align: center;
        }
    }

    &__label {
        font-size: 1.15rem;
        font-weight: 700;
    }
}
