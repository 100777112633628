@import "../../../themes/colors";

.nos_ds__wrapper {
    &-root {
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
        background: rgba(219, 54, 164, 1);
        background: -moz-linear-gradient(
            -45deg,
            rgba(219, 54, 164, 1) 0%,
            rgba(168, 0, 119, 1) 49%,
            rgba(193, 70, 161, 1) 89%,
            rgba(203, 96, 179, 1) 100%
        );
        background: -webkit-gradient(
            left top,
            right bottom,
            color-stop(0%, rgba(219, 54, 164, 1)),
            color-stop(49%, rgb(136, 72, 117)),
            color-stop(89%, rgba(193, 70, 161, 1)),
            color-stop(100%, rgba(203, 96, 179, 1))
        );
        background: -webkit-linear-gradient(
            -45deg,
            rgba(219, 54, 164, 1) 0%,
            rgba(168, 0, 119, 1) 49%,
            rgba(193, 70, 161, 1) 89%,
            rgba(203, 96, 179, 1) 100%
        );
        background: -o-linear-gradient(
            -45deg,
            rgba(219, 54, 164, 1) 0%,
            rgba(168, 0, 119, 1) 49%,
            rgba(193, 70, 161, 1) 89%,
            rgba(203, 96, 179, 1) 100%
        );
        background: -ms-linear-gradient(
            -45deg,
            rgba(219, 54, 164, 1) 0%,
            rgba(168, 0, 119, 1) 49%,
            rgba(193, 70, 161, 1) 89%,
            rgba(203, 96, 179, 1) 100%
        );
        background: linear-gradient(
            135deg,
            rgba(219, 54, 164, 1) 0%,
            rgba(168, 0, 119, 1) 49%,
            rgba(193, 70, 161, 1) 89%,
            rgba(203, 96, 179, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#db36a4', endColorstr='#cb60b3', GradientType=1 );
    }
}
