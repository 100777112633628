@import "../../../themes/_colors";

.nos-brandis__multi-select {
    &__container {
        &-root {
            display: flex;
            padding: 1rem 0rem;
        }

        &-main {
            display: flex;
            flex-direction: column;
        }

        &-select-card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1rem 1rem;
            // align-items: center;
            border: 1px solid $input-outline;
            border-radius: 0.3rem;

            &:hover {
                box-shadow: $card-box-shadow;
            }
            // width: 100px;
            // height: 100px;
        }

        &-options {
            height: 14rem;
            display: flex;
            flex-direction: column;
            overflow: auto;
        }

        &-checkbox-input {
            display: flex;
            align-items: center;
            border: 1px solid $input-outline;
            border-radius: 0.3rem;
            padding: 0.2rem;
            justify-content: center;
            color: $input-outline;
        }
    }

    &__divider {
        &-vertical {
            border-left: 1px solid $divider-color;
            margin: -0.5rem 1rem;
        }

        &-horizontal {
            border-top: 1px solid $divider-color;
            margin: 0.5rem 0;
        }
    }

    &__label {
        &-category {
            font-size: 1.25rem;
            font-weight: 600;
        }
    }

    &__input {
        font-size: 1.15rem;
        margin-left: 0.2rem;
        border: none;
        outline: none;
        padding: 0.2rem 0;
        color: $pill-font;
    }
}
