@import "../../../themes/_colors";

.nos-brandis__sale-type-filter {
    &__container {
        &-main {
            display: flex;
            flex-direction: column;
            padding-right: 1rem;
            margin-top: 3.75rem;
        }
    }

    &__label {
        &-category {
            font-size: 1.25rem;
            font-weight: 600;
        }
    }
}
