// COLORS
$red: red;
$black: #000000;
$white: #ffffff;
$white-shade-1: #fff6f6;
$white-shade-2: #fdf5f5;
$white-shade-3: #e0dfdf;
$blue-shade-1: #2196f3;
$blue-shade-2: #90ccfd;
$blue-shade-3: #6eb9f7;
$grey-shade-1: #d0d0d0;
$grey-shade-2: #e2e2e2;
$grey-shade-3: #c0bfbf;
$grey-shade-4: #828181;
$grey-shade-5: #797777;
$grey-shade-6: #d2c7c7;
$grey-shade-7: #999999;
$purple-shade-1: #6c218a;
$purple-shade-2: #a4b3f3;
$purple-shade-3: #c69fd4;
$cyan-shade-1: #6ee6f7;

// BACKGROUNDS
$primary-background: $white;
$header-backgournd: $white;
$secondary-background: $grey-shade-2;
$view-menu-background: $grey-shade-1;

// Buttons
$button-box-shadow: $grey-shade-7;
// PRIMARY
$button-primary-background: $blue-shade-1;
$button-primary-hover: $blue-shade-2;
$button-primary-active: $blue-shade-3;
// SECONDARY
$button-secondary-background: $grey-shade-4;
$button-secondary-hover: $grey-shade-3;
$button-secondary-active: $grey-shade-2;
// SELECTABLE
$button-selectable-background: $white-shade-1;
$button-selectable-hover: $white-shade-2;
$button-selectable-active: $white-shade-3;

$filter-button-color: $white;

// FONTS
$logo-color: $purple-shade-1;
$copyright: $grey-shade-4;

// SHADOW COLOR
$shadow-color-1: $grey-shade-1;
$shadow-color-2: $grey-shade-5;
// SHADOW
$header-box-shadow: 0 5px 6px -3px $grey-shade-1;
$card-box-shadow: 3px 5px 5px 0px $grey-shade-2;

// Pill
$pill-background: $white;
$border-color: $grey-shade-6;
$pill-font: $grey-shade-4;
$badge-container-background: $grey-shade-4;
$badge-font: $white;

//Input
$input-outline: $grey-shade-2;

//Vertical Divider
$divider-color: $grey-shade-1;

//CARDS
$card-background: $white;
$card-bottom-current: $purple-shade-2;
$card-bottom-today: $blue-shade-2;
$card-bottom-monthly: $cyan-shade-1;
$card-bottom-yearly: $purple-shade-3;
$card-bottom-last-year: $blue-shade-3;
//CHARTS
$charts-container-background: $white;
$charts-container-box-shadow: $grey-shade-7;
