@import "../../../themes/_colors";

.nos-brandis__card {
    &__container {
        &-main {
            display: flex;
            flex-direction: column;
            background: $card-background;
            box-shadow: 0 8px 6px -6px $shadow-color-2;
            border-top-right-radius: 0.3rem;
            border-top-left-radius: 0.3rem;
            min-width: 23.5rem;
        }

        &-top {
            display: flex;
            flex-direction: column;
            padding: 1rem;
        }

        &-bottom {
            display: flex;
            padding: 1rem;
            border-bottom-left-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;
            justify-content: space-between;

            &-left {
                display: flex;
                flex-direction: column;
            }

            &-right {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            &-current {
                background: $card-bottom-current;
            }

            &-today {
                background: $card-bottom-today;
            }

            &-monthly {
                background: $card-bottom-monthly;
            }

            &-yearly {
                background: $card-bottom-yearly;
            }

            &-last-year {
                background: $card-bottom-last-year;
            }
        }
    }

    &__text {
        &-header {
            font-size: 1.25rem;
            color: $grey-shade-5;
        }

        &-amount {
            font-size: 1.75rem;
            margin-top: 0.75rem;
        }

        &-unit {
            &-count {
                font-size: 1.45rem;
            }

            &-label {
                font-size: 1.35rem;
                margin-top: 0.75rem;
            }
        }

        &-customer {
            &-count {
                font-size: 1.45rem;
            }
            &-label {
                font-size: 1.35rem;
                margin-top: 0.75rem;
            }
        }
    }
}
// .nos-ds {

//   &__card {
//     &-container{
//       box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
//       transition: 0.3s;
//       width: 29%;
//       height: 230px;
//       padding: 4px 16px;
//       border-radius: 5px;
//     }
//     &:hover {
//       box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.4), 0 12px 30px 0 rgba(0, 0, 0, 0.38);
//     }
//     &-primary-data {
//       margin: -15px 0 0 0;
//       padding-bottom: 10px;
//     }
//     &-secondary-container{
//       display: flex;
//       flex-direction: row;
//       justify-content:space-between
//     }
//     &-secondary-data-left{
//       // margin: 50px 0 15px 0;
//       padding: 15px 0 5px 0;
//     }
//     &-secondary-data-center{
//       // margin: -34px 0 15px 140px;
//       padding: 15px 0 5px 0;
//     }
//     &-secondary-data-right{
//       // margin: -34px 0 15px 280px;
//       padding: 15px 0 5px 0;
//     }
//     &-logo{
//       margin: -40px 0 0 330px;
//       // display: flex;
//       // justify-content: flex-end;
//     }

//   }
// }
