@import "../../themes/_colors";

body {
    margin: 0;
    height: 100%;
    background-color: $secondary-background;
}

.nos-brandis__dashboard {
    &-root {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-content {
        min-height: 84vh;
        display: flex;
        width: 100%;
    }

    &__common {
        &__container {
            &-main {
                display: flex;
                flex: 1;
                flex-direction: column;
            }

            &-charts {
                display: flex;
                padding: 1rem 2rem;
                justify-content: space-between;
                flex-wrap: wrap;
            }

            &-loader {
                justify-content: center;
            }

            &-filter-verbiage {
                display: flex;
                background: $primary-background;
                flex: 1;
                padding: 2rem;
                justify-content: center;
                border-radius: 0.3rem;
                font-size: 1.25rem;
                margin-top: 1rem;
                box-shadow: 0px 7px 5px -5px grey;
            }
        }
    }
}
