@import "../../../themes/_colors";

.nos-brandis__header {
    &-container {
        &-root {
            color: #000000;
            background-color: $header-backgournd;
            display: flex;
            width: 100%;
            height: 6rem;
            -webkit-box-shadow: $header-box-shadow;
            -moz-box-shadow: $header-box-shadow;
            box-shadow: $header-box-shadow;
            box-sizing: border-box;
            padding: 0 2rem;
            justify-content: space-between;
            align-items: center;
        }

        // left container
        &-branding {
            display: flex;

            &-client-logo {
                height: 5rem;
                object-fit: cover;
                width: 9rem;
            }
        }

        // right container
        &-info {
            &-container {
                display: flex;
                justify-content: center;
                align-items: center;
                // border: 1px solid;
            }

            // &-name {
            //     font-size: 1.2rem;
            //     padding: 0.2rem 3rem 0 0;
            //     font-weight: 600;
            // }

            &-label {
                font-size: 1.15rem;
                font-weight: 700;
            }
        }
    }

    &-application-name {
        font-size: 2.5rem;
        color: $logo-color;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 3rem;
    }
}

// &-button {
//     &-logout {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         font-size: 1.25rem;
//         border-radius: 0.3rem;
//         border: 0;
//         padding: 0.75rem;
//         font-weight: 500;
//         outline: none;
//         cursor: pointer;
//         background: $button-primary-background;

//         &:hover {
//             background-color: $button-primary-hover;
//         }
//     }

//     &-refresh {
//         display: flex;
//         margin-right: 2rem;
//         justify-content: center;
//         align-items: center;
//         font-size: 1.25rem;
//         border-radius: 0.3rem;
//         border: 0;
//         padding: 0.75rem;
//         font-weight: 500;
//         outline: none;
//         cursor: pointer;
//         background: $button-secondary-background;

//         &:hover {
//             background-color: $button-secondary-hover;
//         }
//     }

//     &-icon {
//         width: 1.2rem;
//         height: 1.2rem;
//         padding-right: 0.75rem;
//     }
// }
